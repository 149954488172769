import 'unpoly/dist/unpoly'
import hyperform from 'hyperform'

up.compiler('body', () => {
  document.querySelector('html').classList.remove('no-js')
})

up.compiler('.js-nav-toggle', (element) => {
  const btn = element
  const nav = btn.closest('.navbar').querySelector('.js-nav')

  const toggle = () => {
    nav.classList.toggle('-translate-x-full')
    btn.querySelectorAll('svg').forEach(icon => {
      icon.classList.toggle('opacity-0')
    })
  }

  btn.addEventListener('click', toggle)

  return () => btn.removeEventListener('click')
})

up.compiler('form', () => {
  hyperform.addTranslation("de", {
    TextTooLong: "Bitte kürzen Sie diesen Text auf maximal %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    TextTooShort: "Bitte verwenden Sie zumindest %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    ValueMissing: "Bitte füllen Sie dieses Feld aus.",
    RadioMissing: "Bitte wählen Sie eine dieser Optionen.",
    InvalidEmail: "Bitte geben Sie eine E-Mail-Adresse ein.",
    FileMissing: "Bitte wählen Sie eine Datei.",
    "Please match the requested type.": "Bitte passen Sie die Eingabe dem geforderten Typ an.",
    "Please comply with all requirements.": "Bitte erfüllen Sie alle Anforderungen.",
    "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Bitte verlängern Sie diesen Text auf mindestens %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    "Please use the appropriate format.": "Bitte verwenden Sie das passende Format.",
    "Please enter a comma separated list of email addresses.": "Bitte geben Sie eine komma-getrennte Liste von E-Mail-Adressen an.",
    "Please select a file of the correct type.": "Bitte wählen Sie eine Datei vom korrekten Typ.",
    "Please select one or more files.": "Bitte wählen Sie eine oder mehrere Dateien.",
    "any value": "jeder Wert",
    "any valid value": "jeder gültige Wert",
  });
  hyperform.setLanguage("de");
  hyperform(window);
})

up.compiler('.js-overlay-open', (element) => {
  const btn = element
  const btnClose = btn.closest('.team-member').querySelector('.js-overlay-close')
  const overlay = btn.closest('.team-member').querySelector('.js-overlay')
  const allOverlays = document.querySelectorAll('.js-overlay')

  const show = () => {
    allOverlays.forEach(item => {
      item.classList.remove('translate-x-0')
      item.classList.add('-translate-x-full')
    })
    overlay.classList.add('translate-x-0')
    overlay.classList.remove('-translate-x-full')
  }

  const hide = () => {
    overlay.classList.remove('translate-x-0')
    overlay.classList.add('-translate-x-full')
  }

  btn.addEventListener('click', show)
  btnClose.addEventListener('click', hide)

  return () => btn.removeEventListener('click')
  return () => btnClose.removeEventListener('click')
})

up.compiler('.js-radio-button', (element) => {
  const radioButton = element
  const allRadioButtons = document.querySelectorAll('.js-radio-button')
  const internshipFormGroups = document.querySelector('.internship-form-group').querySelectorAll('.form-group')
  const attestationFormGroups = document.querySelector('.attestation-form-group').querySelectorAll('.form-group')

  const update = () => {
    if (radioButton.checked == true) {
      allRadioButtons.forEach(item => {
        item.closest('.custom-radio').classList.remove('-checked')
      })
      radioButton.closest('.custom-radio').classList.add('-checked')
      if (radioButton.closest('[value="internship"]')) {
        internshipFormGroups.forEach((formGroup, index) => {
          formGroup.classList.remove('hidden')
          if (index === 0) {
            formGroup.querySelector('input').setAttribute('required', 'required')
          }
        })
      } else {
        internshipFormGroups.forEach((formGroup, index) => {
          formGroup.classList.add('hidden')
          if (index === 0) {
            formGroup.querySelector('input').removeAttribute('required')
          }
        })
      }
      if (radioButton.closest('[value="attestation"]')) {
        attestationFormGroups.forEach((formGroup, index) => {
          formGroup.classList.remove('hidden')
          if (index === 2 || index == 3) {
            formGroup.querySelector('input').setAttribute('required', 'required')
          }
        })
      } else {
        attestationFormGroups.forEach((formGroup, index) => {
          formGroup.classList.add('hidden')
          if (index === 2 || index == 3) {
            formGroup.querySelector('input').removeAttribute('required')
          }
        })
      }
    }
  }

  const focus = () => {
    radioButton.closest('.custom-radio').classList.toggle('-focused')
  }

  window.addEventListener('load', update)
  radioButton.addEventListener('change', update)
  radioButton.addEventListener('focusin', focus)
  radioButton.addEventListener('focusout', focus)

  return () => radioButton.removeEventListener('change')
  return () => radioButton.removeEventListener('focusin')
  return () => radioButton.removeEventListener('focusout')
})

up.compiler('.js-file-input', (element) => {
  const input = element;

	const hint	 = input.closest('.custom-file-input').querySelector('.hint')
  let labelVal = hint.innerHTML;

	input.addEventListener( 'change', function( e ) {
		let fileName = '';
		if( this.files && this.files.length > 1 ) {
      fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
    }
		else {
      fileName = e.target.value.split( '\\' ).pop()
    }

		if( fileName )
			hint.innerHTML = fileName;
		else
			hint.innerHTML = labelVal;
	});

  const focus = () => {
    input.closest('.custom-file-input').querySelector('.form-control').classList.toggle('-focused')
  }

  input.addEventListener('focusin', focus)
  input.addEventListener('focusout', focus)

  return () => input.removeEventListener('change')
  return () => input.removeEventListener('focusin')
  return () => input.removeEventListener('focusout')
})
